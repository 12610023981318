import React from 'react';
import { Box } from 'theme-ui';
import Navigation from '@components/Navigation';
import Drawer from '@components/Drawer';
import useSiteMetadata from '@helpers-blog/useSiteMetadata';
import { graphql, useStaticQuery } from "gatsby";
import { languageOptions } from '../../../../constants/languages';


const query = graphql`
    query MenuContentQuery {
        site {
            siteMetadata {
                locale
            }
        }
        it: allBlockContent(
            filter: {page: {in: ["site-it/shared"]}, identifier: {in: ["header"]}}
        ) {
            nodes {
                collection {
                    title {
                        text
                    }
                    buttons {
                        text
                        link
                    }
                }
            }
        }
        es: allBlockContent(
            filter: {page: {in: ["site-es/shared"]}, identifier: {in: ["header"]}}
        ) {
            nodes {
                collection {
                    title {
                        text
                    }
                    buttons {
                        text
                        link
                    }
                }
            }
        }
        uk: allBlockContent(
            filter: {page: {in: ["site-uk/shared"]}, identifier: {in: ["header"]}}
        ) {
            nodes {
                collection {
                    title {
                        text
                    }
                    buttons {
                        text
                        link
                    }
                }
            }
        }
        us: allBlockContent(
            filter: {page: {in: ["site-us/shared"]}, identifier: {in: ["header"]}}
        ) {
            nodes {
                collection {
                    title {
                        text
                    }
                    buttons {
                        text
                        link
                    }
                }
            }
        }
        pt: allBlockContent(
            filter: {page: {in: ["site-pt/shared"]}, identifier: {in: ["header"]}}
        ) {
            nodes {
                collection {
                    title {
                        text
                    }
                    buttons {
                        text
                        link
                    }
                }
            }
        }
    }
`
const useMenu = () => {
  const result = useStaticQuery(query)
  const supportedLocales = ['uk', 'us', 'it', 'es', 'pt']
  const locale = supportedLocales.includes(result.site.siteMetadata.locale) ? result.site.siteMetadata.locale : 'uk'
  const [main, languages] = result[locale].nodes[0].collection
  return [
    main && {
      title: main.title.text,
      items: main.buttons.map((item) => ({
        name: item.text,
        slug: item.link,
      }))
    },
    languages && {
      title: languages.title.text,
      items: languageOptions.map(i => ({ name: i.title, url: i.link }))
    }
  ].filter(Boolean)
}
const styles = {
  desktopMenu: {
    display: [`none`, null, `block`]
  },
  mobileMenu: {
    display: [`block`, null, `none`]
  },
  desktopMenuWrapper: {
    justifyContent: 'flex-end'
  }
}

export const HeaderMenu = ({ mobileMenu = [], amp, locale }) => {
  const { headerMenu } = useSiteMetadata()
  const extendedMenu = useMenu(locale)
  const desktopMenuNav = (
    <Navigation
      variant='horizontal'
      items={headerMenu}
      wrapperStyle={styles.desktopMenuWrapper}
    />
  );

  const mobileMenuNav = (
    <Drawer amp={amp}>
      <Navigation
        variant='vertical'
        headingProps={{ variant: 'h3' }}
        items={[
          ...extendedMenu,
          ...mobileMenu,
        ]}
      />
    </Drawer>
  )

  return (
    <>
      <Box sx={styles.desktopMenu}>{desktopMenuNav}</Box>
      <Box sx={styles.mobileMenu}>{mobileMenuNav}</Box>
    </>
  )
}

