import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, Flex, Box, css } from 'theme-ui'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { Header } from '../Header/Header'
import { normalizeBlockContentNodes } from '@blocks-helpers';
import Footer from '@solid-ui-blocks/Footer/Block01'

export const Layout = ({ children, pageContext, location, background, customTheme = {}, amp=false, ...props }) => {
  const {allBlockContents, allBlockContent} = props?.data || props.pageResources?.json?.data || ({allBlockContents: {}, allBlockContent: {}});
  const content = normalizeBlockContentNodes(allBlockContent?.nodes || allBlockContents?.nodes);
  const newTheme = {...theme, colors: {...theme.colors, background: background || theme.colors.background}, ...customTheme}
  return (
    <ThemeProvider theme={newTheme}>
      <pageContextProvider.Provider value={{ pageContext, location }}>
      <Flex variant='layout.layout' >
          <Global styles={css(theme => theme.global)} />
          <Header content={content?.['header']} amp={amp} />
          <Box variant='layout.body' mt={[3, 4]} mb={5}>{children}</Box>
          <Footer content={content?.['footer'] || []} customStyles={{"a": {color: `omegaDarker`}}} amp={amp} forceDark />
        </Flex>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}
