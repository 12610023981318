import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from 'theme-ui';
import { FaBars, FaTimes } from 'react-icons/fa';

const styles = {
  panel: (amp) => ({
    width: `75%`,
    height: `100vh`,
    transition: `all .5s cubic-bezier(.77,0,.175,1)`,
    ...(amp
      ? {}
      : {
          zIndex: 99,
          position: `fixed`,
          top: 0,
          right: 0,
          transform: `translate(100%, 0)`,
          bg: `contentBg`,
          open: {
            transform: `translate(0, 0)`,
            boxShadow: `0 0 45px 0 rgba(0,0,0,.25)`,
          },
        }),
  }),
  panelContent: (amp) => ({
    position: `relative`,
    height: `100%`,
    ...(amp ? {} : { zIndex: 2 }),
    bg: `contentBg`,
    overflowY: `scroll`,
    fontSize: 3,
    p: 5,
  }),
  overlay: {
    position: `fixed`,
    top: 0,
    right: `100%`,
    size: `100%`,
    bg: `#222`,
    opacity: 0,
    transition: `opacity .5s cubic-bezier(.77,0,.175,1)`,
    zIndex: 90,
  },
  overlayOpen: {
    right: 0,
    opacity: 0.9,
  },
  handler: {
    display: ['', null, 'none'], //to avoid ssr rehydration issue
    transition: `left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)`,
    left: -4,
  },
  handlerOpen: {
    position: `fixed`,
    zIndex: 99999,
    left: 4,
    top: 4,
  },
};

const Drawer = ({ container, amp, width, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleSwitch = () => {
    setOpen(!open);
  };

  const handlerStyle = open
    ? {
        ...styles.handler,
        ...styles.handlerOpen,
      }
    : styles.handler;

  const handler = (
      <IconButton
        sx={handlerStyle}
        aria-label='Menu'
        {...(amp
          ? { on: 'tap:sidebar.toggle' }
          : {
              onClick: handleSwitch,
            })}
        {...props}
      >
        {open ? <FaTimes /> : <FaBars />}
      </IconButton>
  );

  return (
    <>
      {handler}
      {!amp ? (
        <>
          <Box
            sx={{
              ...styles.panel(false),
              ...(open && styles.panel(false).open),
            }}
          >
            <Box sx={styles.panelContent(false)}>{open && props.children}</Box>
          </Box>
          <Box
            sx={{ ...styles.overlay, ...(open && styles.overlayOpen) }}
            onClick={handleSwitch}
          />
        </>
      ) : (
          <amp-sidebar
            id='sidebar'
            style={{ ...styles.panel(true), ...styles.panel(true).open }}
            layout='nodisplay'
            side='right'
          >
            <IconButton
              id="sidebar-close"
              sx={{
                ...styles.handler,
                ...styles.handlerOpen,
                left: "auto",
                right: "2rem"
              }}
              aria-label='Menu'
              on='tap:sidebar.toggle'
              {...props}
            >
              <FaTimes />
              </IconButton>
            <Box sx={styles.panelContent(true)}>{props.children}</Box>
          </amp-sidebar>
      )}
    </>
  );
};

export default Drawer;

Drawer.defaultProps = {
  width: 300,
  container: null,
};

Drawer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  container: PropTypes.instanceOf(PropTypes.element),
};
