import React, { useContext } from 'react'
import { Box, Input, IconButton } from 'theme-ui'
import { FaSearch } from 'react-icons/fa'
import pageContextProvider from '@helpers/pageContextProvider'
import styles from './Search.styles'

const customStyles = {
  notLoaded: {
    cursor: `pointer`,
    color: `transparent`
  }
}

const SearchPlaceholders = {
  it: 'Cerca novità, articoli e altro...',
  es: 'Busca noticias, artículos y más...',
  us: 'Discover news, articles and more...',
  uk: 'Discover news, articles and more...'
}

const SearchInput = ({ isLoaded, focus, loadSearch, ...props }) => {
  const { pageContext: { locale = 'uk' } } = useContext(pageContextProvider);
  const placeholder = SearchPlaceholders[locale];

  return (
  <>
    <IconButton
      sx={styles.mobileTrigger}
      onClick={loadSearch || props.onFocus}
      aria-label='Search'
    >
      <FaSearch />
    </IconButton>
    <Box sx={styles.form({ focus })}>
      <FaSearch style={styles.searchIcon} />
      <Input
        css={!isLoaded && customStyles.notLoaded}
        sx={styles.input}
        type='text'
        placeholder={placeholder}
        aria-label='Search'
        onClick={loadSearch ? loadSearch : undefined}
        autoFocus={isLoaded ? true : undefined}
        {...props}
      />
    </Box>
  </>
)}

export default SearchInput
