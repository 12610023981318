import React, { useEffect, useState } from 'react'
import { useColorMode } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Logo from '@components/Logo'
import LogoAMP from '../../flow-ui-components/LogoAMP'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import { Fragment } from 'react'

export const HeaderLogo = ({ content,amp, ...props }) => {
  const { title = "header logo" } = useSiteMetadata()
  const [colorMode] = useColorMode() 
  const [isDark, setIsDark] = useState(colorMode === `dark`)
  useEffect(() => {
    setIsDark(colorMode === `dark`)
  }, [colorMode])

  // const { logo, logoDark } = useStaticQuery(logoQuery)
  const { logo, logoDark, logoAMP, logoDarkAMP } = useStaticQuery(logoQuery) 

  const logoNormal = getImage(logo)
  const LogoDark = getImage(logoDark)
  const logoAMPVal = logoAMP.childImageSharp.fluid
  const logoDarkAMPVal = logoDarkAMP.childImageSharp.fluid
  if (!logoNormal) return null
  const condition = isDark && LogoDark;
  return amp ? <Fragment>
    <LogoAMP image={logoDarkAMPVal} title={title} alt={title} style={!condition && {display: "none"}} {...props}  />
    <LogoAMP image={logoAMPVal} title={title} alt={title} style={condition && {display: "none"}} {...props} />
  </Fragment>: 
    <Fragment>
    <Logo image={LogoDark} title={title} alt={title} sx={!condition && {display: "none"}} {...props}  />
    <Logo image={logoNormal} title={title} alt={title} sx={condition && {display: "none"}} {...props} />
  </Fragment>
}

const logoQuery = graphql`
  query CustomLogoQuery {
    logo: file(
      absolutePath: { regex: "/logo.(jpeg|jpg|gif|png)/" }
      sourceInstanceName: { eq: "asset" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          layout: FIXED
          quality: 100
        )
      }
    }
    logoDark: file(
      absolutePath: { regex: "/logo-dark.(jpeg|jpg|gif|png)/" }
      sourceInstanceName: { eq: "asset" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          layout: FIXED
          quality: 100
        )
      }
    }
    logoAMP: file(
      absolutePath: { regex: "/logo.(jpeg|jpg|gif|png)/" }
      sourceInstanceName: { eq: "asset" }
    ) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 285) {
              src
              srcSet
              sizes
            }
      }
    }
    logoDarkAMP: file(
      absolutePath: { regex: "/logo-dark.(jpeg|jpg|gif|png)/" }
      sourceInstanceName: { eq: "asset" }
    ) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 285) {
              src
              srcSet
              sizes
            }
      }
    }
  }
`